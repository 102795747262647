import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PopupProductForm from '../PopupProductForm/PopupProductForm';
import Image from '../Image/Image';
import LazyLoader from '../../containers/LazyLoader/LazyLoader';
import setActiveProduct from '../../../actions/setActiveProduct';

import './MainContent.css';

class MainContent extends Component {
    imgRefs = this.props.products.map(() => React.createRef());

    state = {
        activeProduct: {},
        isHorizontalPicture: {}
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.productId !== nextProps.productId) {
            this.imgRefs = nextProps.products.map(() => React.createRef());
        }
    }

    handleDetailsClick = (activeProduct) => () => {
        this.props.togglePopup(true);
        this.setState({
            activeProduct
        });

        window.dataLayer.push({
            event: 'details_click'
        });
    };

    handleOrderClick = (activeProduct) => () => {
        this.props.togglePopup(false);
        this.props.setActiveProduct(activeProduct);
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    };

    checkImgProperties = (index) => {
        const pictureWidth = this.imgRefs[index].current.naturalWidth;
        const pictureHeight = this.imgRefs[index].current.naturalHeight

        this.setState({
            isHorizontalPicture: {
                ...this.state.isHorizontalPicture,
                [index]: pictureWidth > pictureHeight
            }
        });
    };

    createProductContainer = (product, productText) => {
        const { isHorizontalPicture } = this.state;
        return (
            <div className='product' key={product.id}>
                <div className='product_img_container'>
                    <LazyLoader>
                        <Image
                            className={classNames('product_img', {
                                'product_img_horizontal': isHorizontalPicture[product.id - 1]
                            })} 
                            src={product.photo[0]}
                            alt={`product - ${product.photo[0]}`}
                            ref={this.imgRefs[product.id - 1]}
                            onloadCallback={() => this.checkImgProperties(product.id - 1)}
                        />
                    </LazyLoader>
                </div>
                <div className='product_buttonsContainer'>
                    <div
                        className='product_link productOrder'
                        onClick={this.handleOrderClick(product)}
                    >
                        {productText.order}
                    </div>
                    <div
                        className='product_link productDetails'
                        onClick={this.handleDetailsClick(product)}
                    >
                        {productText.details}
                    </div>
                </div>
                <div className='product_price'>
                    {
                        product.price === 0
                            ? productText.negotiablePrice
                            : `${productText.pricePrev} ${product.price} UAH`
                    }
                </div>
                <div className='product_article'>артикул: {product.article}</div>
            </div>
        );
    };

    render() {
        const { 
            isPopupProductShown,
            products,
            togglePopup,
            productText,
            fabric
        } = this.props;
        const { activeProduct } = this.state;

        return (
            <div className='contentContainer'>
                {
                    isPopupProductShown && 
                        <PopupProductForm
                            togglePopup={togglePopup}
                            handleOrderClick={this.handleOrderClick}
                            activeProduct={activeProduct}
                            fabric={fabric}
                            productText={productText}
                        />
                }
                <div className='content'>
                    {
                        products.map((product) =>
                            this.createProductContainer(product, productText))
                    }
                </div>
            </div>
        );
    }
};

export default connect(null, { setActiveProduct })(MainContent);