const addIds = (products) => {
    const productsWithId = products
        .map(section => ({...section, value: section.value
            .map((product, i) => ({...product, id: i + 1 }))
        }));

    return productsWithId;
}

export default addIds;
