import { memo, createElement, forwardRef, useEffect, useState } from 'react';

const Image = forwardRef(({ className, src, alt, onloadCallback }, ref) => {
    const [imgSrc, setImgSrc] = useState(src);
    
    useEffect(() => {
        if (imgSrc !== src) {
            setImgSrc(src);
        }
    }, [src, imgSrc])

    const image = createElement('img', {
        src: imgSrc,
        className,
        alt,
        ref,
        onLoad: onloadCallback,
    }, null);

    if (imgSrc !== src) return null;
    return image;
});

export default memo(Image);
