import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

import './LazyLoader.css';

export default function LazyLoader({ children }) {
  return (
      <LazyLoad
        once
        offset={150}
      >
        {children}
      </LazyLoad>
  );
};

LazyLoader.propTypes = {
  children: PropTypes.node.isRequired,
};
